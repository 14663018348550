import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Paper,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Avatar,
  Button,
  TextField
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const Register = () => {
  const navigate = useNavigate();
  const { register } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateForm = () => {
    if (!username || !password || !confirmPassword) {
      setError('All fields are required');
      return false;
    }

    if (username.length < 3) {
      setError('Username must be at least 3 characters long');
      return false;
    }

    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    
    if (!validateForm()) return;

    try {
      setLoading(true);
      console.log('Starting registration process...', { username, password });
      await register(username, password);
      console.log('Registration successful, redirecting...');
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response?.data?.msg || 'Failed to create an account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #0088cc 0%, #005580 100%)',
        py: 8
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#0088cc', width: 56, height: 56 }}>
            <PersonAddIcon sx={{ fontSize: 32 }} />
          </Avatar>
          <Typography 
            component="h1" 
            variant="h5" 
            sx={{ 
              mb: 3,
              color: '#000000',
              fontWeight: 600,
              fontSize: '1.75rem'
            }}
          >
            Регистрация
          </Typography>

          <Box 
            component="form" 
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: '100%' }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Registration successful! Redirecting to login...
              </Alert>
            )}
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Имя пользователя"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
              sx={{
                '& label': { color: '#666666' },
                '& label.Mui-focused': { color: '#0088cc' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#cccccc' },
                  '&:hover fieldset': { borderColor: '#0088cc' },
                  '&.Mui-focused fieldset': { borderColor: '#0088cc' },
                },
                '& input': { 
                  color: '#000000',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000000'
                  }
                }
              }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              sx={{
                '& label': { color: '#666666' },
                '& label.Mui-focused': { color: '#0088cc' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#cccccc' },
                  '&:hover fieldset': { borderColor: '#0088cc' },
                  '&.Mui-focused fieldset': { borderColor: '#0088cc' },
                },
                '& input': { 
                  color: '#000000',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000000'
                  }
                }
              }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Подтвердите пароль"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
              sx={{
                '& label': { color: '#666666' },
                '& label.Mui-focused': { color: '#0088cc' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#cccccc' },
                  '&:hover fieldset': { borderColor: '#0088cc' },
                  '&.Mui-focused fieldset': { borderColor: '#0088cc' },
                },
                '& input': { 
                  color: '#000000',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000000'
                  }
                }
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2,
                bgcolor: '#0088cc',
                '&:hover': {
                  bgcolor: '#006699'
                }
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Зарегистрироваться'}
            </Button>
            
            <Button
              component={Link}
              to="/login"
              fullWidth
              variant="outlined"
              sx={{
                color: '#0088cc',
                borderColor: '#0088cc',
                textDecoration: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(0, 136, 204, 0.08)',
                  borderColor: '#006699',
                  color: '#006699'
                }
              }}
            >
              Уже есть аккаунт? Войти
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Register;
