import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { homeService } from '../services/homeService';
import { getHomeSeo } from '../services/seoApi';
import MetaTags from '../components/MetaTags.jsx';
import SearchSection from '../components/home/SearchSection';
import CatalogInfoSection from '../components/home/CatalogInfoSection';
import StatisticsSection from '../components/home/StatisticsSection';
import PopularCategoriesSection from '../components/home/PopularCategoriesSection';
import { createPageComponents, DefaultFallback, preloadComponents } from '../services/lazyLoadService';

// Данные для разделов
import { categories, popularCategories, searchCategories, statistics } from '../data/homeData';

// Определяем приоритеты компонентов
const componentPriorities = {
  PopularChannelsSection: true, // Высокий приоритет для популярных каналов
  AddChannelSection: false,
  RecommendedChannelsSection: false,
  RecentChannelsSection: false
};

// Создаем ленивые компоненты с использованием нового сервиса и приоритетов
const {
  AddChannelSection,
  PopularChannelsSection,
  RecommendedChannelsSection,
  RecentChannelsSection
} = createPageComponents({
  AddChannelSection: () => import('../components/home/AddChannelSection'),
  PopularChannelsSection: () => import('../components/channel/PopularChannelsSection'),
  RecommendedChannelsSection: () => import('../components/channel/RecommendedChannelsSection'),
  RecentChannelsSection: () => import('../components/channel/RecentChannelsSection')
}, {
  delayIndicator: true,
  minDelay: 100, // Уменьшаем задержку с 300 до 100 мс
  fallback: <DefaultFallback />
}, componentPriorities);

const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Состояние для хранения загруженных данных
  const [channelData, setChannelData] = useState({
    recent: [],
    popular: [],
    recommended: []
  });

  // Состояние для SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'TeleHunt - Каталог Telegram каналов, групп и ботов',
    description: 'Найдите лучшие Telegram каналы в нашем каталоге. Мы собрали самые интересные и полезные каналы в одном месте.',
    keywords: 'telegram, каналы, каталог, подборка',
    ogTitle: 'TeleHunt - Каталог Telegram каналов',
    ogDescription: 'Найдите лучшие Telegram каналы в нашем каталоге',
    ogImage: '/images/seo/og-image.jpg',
    ogType: 'website',
    twitterCard: 'summary_large_image',
    canonicalUrl: '',
    schema: {}
  });

  // Обработчик обновления данных
  const handleDataUpdate = useCallback((data) => {
    console.log('[INFO] Получено событие обновления данных');
    if (data) {
      setChannelData({
        recent: data.recent || [],
        popular: data.popular || [],
        recommended: data.recommended || []
      });
      
      if (data.seo) {
        setSeoMetadata(data.seo);
      }
    }
  }, []);

  // Определяем, является ли устройство мобильным
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Перенаправляем на страницу категории "Все" с параметром поиска
      navigate(`/category/all?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleCategoryClick = (category) => {
    navigate(`/category/${encodeURIComponent(category.slug || category.id)}`);
  };
  
  // Предзагрузка некритических компонентов
  const preloadRemainingComponents = useCallback(() => {
    // Используем requestIdleCallback для загрузки в свободное время
    const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 50));
    
    idleCallback(() => {
      preloadComponents([
        () => import('../components/channel/RecommendedChannelsSection'),
        () => import('../components/channel/RecentChannelsSection'),
        () => import('../components/home/AddChannelSection')
      ]);
    });
  }, []);
  
  // Подписка на события обновления данных
  useEffect(() => {
    // Подписываемся на события обновления данных
    homeService.subscribeToDataUpdates(handleDataUpdate);
    
    // Отписываемся при размонтировании компонента
    return () => {
      homeService.unsubscribeFromDataUpdates(handleDataUpdate);
    };
  }, [handleDataUpdate]);
  
  // Загрузка данных при монтировании компонента
  useEffect(() => {
    let isMounted = true;
    
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        
        // Создаем запрос с таймаутом
        const fetchWithTimeout = async (promise, timeoutMs = 5000) => {
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Таймаут загрузки данных')), timeoutMs);
          });
          return Promise.race([promise, timeoutPromise]);
        };
        
        // Для мобильных устройств загружаем только критически важные данные
        const dataPromise = isMobile 
          ? homeService.getCriticalData()
          : homeService.getHomeData();
          
        const data = await fetchWithTimeout(dataPromise, 5000);
        
        if (data && isMounted) {
          // Устанавливаем данные о каналах
          setChannelData({
            recent: data.recent || [],
            popular: data.popular || [],
            recommended: data.recommended || []
          });
          
          // Устанавливаем SEO-метаданные, если они есть
          if (data.seo) {
            setSeoMetadata(data.seo);
          }
          
          // Запускаем предзагрузку некритических компонентов
          preloadRemainingComponents();
          
          // Если это мобильное устройство, загружаем оставшиеся данные после отображения критически важного контента
          if (isMobile) {
            // Используем requestIdleCallback для загрузки в свободное время
            const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 50));
            idleCallback(() => {
              if (isMounted) loadRemainingData(data);
            });
          }
        }
      } catch (error) {
        console.error('[Ошибка] Home - Ошибка при загрузке данных:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    // Загрузка оставшихся данных для мобильных устройств
    const loadRemainingData = async (initialData) => {
      try {
        const fullData = await homeService.loadRemainingData(initialData);
        
        if (isMounted) {
          setChannelData({
            recent: fullData.recent || [],
            popular: fullData.popular || [],
            recommended: fullData.recommended || []
          });
          
          if (fullData.seo) {
            setSeoMetadata(fullData.seo);
          }
        }
      } catch (error) {
        console.error('[Ошибка] Home - Ошибка при загрузке оставшихся данных:', error);
      }
    };
    
    fetchInitialData();
    
    return () => {
      isMounted = false;
    };
  }, [isMobile, preloadRemainingComponents, handleDataUpdate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#f8f9fa' }}>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={getHomeSeo}
        defaultTitle="TeleHunt - Каталог Telegram каналов, групп и ботов"
        defaultDescription="Найдите лучшие Telegram каналы в нашем каталоге. Мы собрали самые интересные и полезные каналы в одном месте."
      />
      
      <Container component="main" sx={{ flexGrow: 1, py: 4, display: 'flex', flexDirection: 'column' }}>
        <SearchSection 
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
          handleCategoryClick={handleCategoryClick}
          searchCategories={searchCategories}
          categories={categories}
        />

        <StatisticsSection statistics={statistics} />
        
        <PopularCategoriesSection categories={popularCategories} />

        {/* Популярные каналы - приоритетная загрузка */}
        {!isLoading && channelData.popular.length > 0 && (
          <PopularChannelsSection channels={channelData.popular} />
        )}

        {/* Информация о каталоге */}
        <CatalogInfoSection />

        {/* Рекомендуемые каналы */}
        {!isLoading && channelData.recommended.length > 0 && (
          <RecommendedChannelsSection channels={channelData.recommended} />
        )}

        {/* Недавно добавленные */}
        {!isLoading && channelData.recent.length > 0 && (
          <RecentChannelsSection channels={channelData.recent} hideSubscribers={true} limit={6} />
        )}

        {/* Добавить канал */}
        <AddChannelSection />
      </Container>
    </Box>
  );
};

export default Home;
