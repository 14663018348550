import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Box, 
  TextField, 
  Button, 
  Alert,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar
} from '@mui/material';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const RemovalRequest = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Запрос на удаление канала | TeleHunt.ru',
    description: 'Форма запроса на удаление Telegram канала из каталога TeleHunt.ru',
    keywords: 'удаление канала, запрос на удаление, телеграм каталог'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('removal');
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);

  const [formData, setFormData] = useState({
    channelName: '',
    reason: 'copyright',
    description: '',
    contactEmail: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь будет логика отправки формы
    setShowSuccess(true);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseSnackbar = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={() => getPageSeo('removal')}
        defaultTitle="Запрос на удаление канала | TeleHunt.ru"
        defaultDescription="Форма запроса на удаление Telegram канала из каталога TeleHunt.ru"
      />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Запрос на удаление информации
        </Typography>
        <Paper sx={{ p: 4, mt: 3 }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ '& > *': { mb: 3 } }}>
            <Typography variant="body1" paragraph>
              Используйте эту форму для запроса на удаление информации из нашего каталога, 
              в том числе по требованию Роскомнадзора.
            </Typography>

            <TextField
              fullWidth
              required
              label="Название канала"
              name="channelName"
              value={formData.channelName}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />

            <FormControl component="fieldset" sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Причина удаления
              </Typography>
              <RadioGroup
                name="reason"
                value={formData.reason}
                onChange={handleChange}
              >
                <FormControlLabel 
                  value="copyright" 
                  control={<Radio />} 
                  label="Нарушение авторских прав" 
                />
                <FormControlLabel 
                  value="personal" 
                  control={<Radio />} 
                  label="Личные данные" 
                />
                <FormControlLabel 
                  value="rkn" 
                  control={<Radio />} 
                  label="Требование РКН" 
                />
                <FormControlLabel 
                  value="other" 
                  control={<Radio />} 
                  label="Другое" 
                />
              </RadioGroup>
            </FormControl>

            <TextField
              fullWidth
              required
              multiline
              rows={4}
              label="Описание причины"
              name="description"
              value={formData.description}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />

            <TextField
              fullWidth
              required
              type="email"
              label="Контактный email"
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />

            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              size="large"
            >
              Отправить запрос
            </Button>
          </Box>
        </Paper>

        <Snackbar 
          open={showSuccess} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity="success"
            sx={{ width: '100%' }}
          >
            Ваш запрос успешно отправлен. Мы рассмотрим его в ближайшее время.
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default RemovalRequest;
