import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const Privacy = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Политика конфиденциальности | TeleHunt.ru',
    description: 'Политика конфиденциальности каталога Telegram каналов TeleHunt.ru',
    keywords: 'политика конфиденциальности, персональные данные, телеграм каталог'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('privacy');
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);

  return (
    <>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={() => getPageSeo('privacy')}
        defaultTitle="Политика конфиденциальности | TeleHunt.ru"
        defaultDescription="Политика конфиденциальности каталога Telegram каналов TeleHunt.ru"
      />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Политика конфиденциальности
        </Typography>
        <Paper sx={{ p: 4, mt: 3 }}>
          <Box sx={{ '& > *': { mb: 3 } }}>
            <Typography variant="h6" gutterBottom>
              1. Общие положения
            </Typography>
            <Typography variant="body1" paragraph>
              Настоящая политика конфиденциальности описывает, как мы собираем, используем и защищаем 
              информацию, которую вы предоставляете при использовании нашего каталога Telegram каналов.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Сбор информации
            </Typography>
            <Typography variant="body1" paragraph>
              Мы собираем только ту информацию, которая необходима для функционирования Каталога. 
              Это может включать в себя: адрес электронной почты, IP-адрес, данные о браузере и 
              устройстве, а также информацию о Telegram каналах, которые вы добавляете в Каталог.
            </Typography>

            <Typography variant="h6" gutterBottom>
              3. Использование информации
            </Typography>
            <Typography variant="body1" paragraph>
              Собранная информация используется для: обеспечения работы Каталога, улучшения 
              пользовательского опыта, связи с пользователями по вопросам, связанным с Каталогом, 
              и предоставления статистики о популярности каналов.
            </Typography>

            <Typography variant="h6" gutterBottom>
              4. Защита информации
            </Typography>
            <Typography variant="body1" paragraph>
              Мы принимаем все необходимые меры для защиты вашей информации от несанкционированного 
              доступа, изменения, раскрытия или уничтожения. Однако, мы не можем гарантировать 
              абсолютную безопасность информации, передаваемой через интернет.
            </Typography>

            <Typography variant="h6" gutterBottom>
              5. Изменение политики конфиденциальности
            </Typography>
            <Typography variant="body1">
              Мы оставляем за собой право изменять настоящую Политику конфиденциальности в 
              одностороннем порядке без предварительного уведомления пользователей. Актуальная 
              версия Политики всегда доступна на этой странице.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Privacy;
