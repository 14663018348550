import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Paper,
  Divider,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchIcon from '@mui/icons-material/Search';
import SecurityIcon from '@mui/icons-material/Security';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const FAQ = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const location = useLocation();
  const formRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [helpfulRatings, setHelpfulRatings] = useState({});
  const [question, setQuestion] = useState('');
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');

  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Часто задаваемые вопросы | TeleHunt.ru',
    description: 'Ответы на часто задаваемые вопросы о каталоге Telegram каналов TeleHunt.ru',
    keywords: 'FAQ, вопросы и ответы, телеграм каталог'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('faq');
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleHelpfulRating = async (sectionIndex, itemIndex, isHelpful) => {
    const key = `${sectionIndex}-${itemIndex}`;
    if (helpfulRatings[key]) return;

    try {
      // Отправляем оценку на сервер
      const response = await fetch('/api/faq/rate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sectionIndex,
          itemIndex,
          isHelpful
        })
      });

      if (!response.ok) throw new Error('Failed to save rating');

      setHelpfulRatings(prev => ({
        ...prev,
        [key]: isHelpful
      }));

      setSnackbar({
        open: true,
        message: isHelpful ? 'Спасибо за вашу оценку!' : 'Спасибо за отзыв. Мы постараемся улучшить ответ.',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving rating:', error);
      setSnackbar({
        open: true,
        message: 'Произошла ошибка при сохранении оценки',
        severity: 'error'
      });
    }
  };

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    
    if (!user) {
      setSnackbar({
        open: true,
        message: 'Пожалуйста, войдите в систему, чтобы задать вопрос',
        severity: 'warning'
      });
      return;
    }

    if (!question.trim()) {
      setSnackbar({
        open: true,
        message: 'Пожалуйста, введите ваш вопрос',
        severity: 'error'
      });
      return;
    }

    // Валидация email
    if (email && !email.match(/^\S+@\S+\.\S+$/)) {
      setSnackbar({
        open: true,
        message: 'Пожалуйста, введите корректный email',
        severity: 'error'
      });
      return;
    }

    try {
      const response = await fetch('/api/questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          question: question.trim(),
          email: email.trim() || undefined,
          telegram: telegram.trim() || undefined
        })
      });

      if (!response.ok) throw new Error('Failed to send question');

      setFeedbackOpen(false);
      setQuestion('');
      setEmail('');
      setTelegram('');
      setSnackbar({
        open: true,
        message: 'Спасибо за ваш вопрос! Мы ответим вам в ближайшее время.',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error sending question:', error);
      setSnackbar({
        open: true,
        message: 'Произошла ошибка при отправке вопроса',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('scroll') === 'form' && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      setFeedbackOpen(true); // Автоматически открываем форму
    }
  }, [location]);

  const faqSections = [
    {
      title: 'О каталоге',
      icon: <SearchIcon />,
      items: [
        {
          question: 'Что такое Telegram Catalog?',
          answer: 'Telegram Catalog - это удобный каталог Telegram-каналов, где вы можете найти интересные каналы по различным тематикам. Мы тщательно отбираем и проверяем каждый канал перед добавлением в каталог.'
        },
        {
          question: 'Как искать каналы в каталоге?',
          answer: 'Вы можете искать каналы несколькими способами:\n- Через поиск по названию или описанию\n- Используя фильтры по категориям\n- Просматривая популярные каналы на главной странице'
        },
        {
          question: 'Все каналы в каталоге проверенные?',
          answer: 'Да, мы проверяем каждый канал перед публикацией. Проверка включает:\n- Соответствие правилам Telegram\n- Отсутствие спама и мошенничества\n- Актуальность контента\n- Соответствие указанной тематике'
        }
      ]
    },
    {
      title: 'Добавление канала',
      icon: <AddCircleOutlineIcon />,
      items: [
        {
          question: 'Как добавить свой канал в каталог?',
          answer: 'Чтобы добавить канал:\n1. Зарегистрируйтесь на сайте\n2. Нажмите кнопку "Добавить канал"\n3. Заполните информацию о канале\n4. Дождитесь проверки модераторами'
        },
        {
          question: 'Сколько времени занимает модерация?',
          answer: 'Обычно модерация занимает от 1 до 3 рабочих дней. Мы тщательно проверяем каждый канал, чтобы поддерживать высокое качество каталога.'
        },
        {
          question: 'Это бесплатно?',
          answer: 'Да, добавление канала в каталог абсолютно бесплатно. Мы не берем плату за размещение каналов.'
        }
      ]
    },
    {
      title: 'О Telegram',
      icon: <TelegramIcon />,
      items: [
        {
          question: 'Что такое Telegram?',
          answer: 'Telegram - это современный мессенджер, который предлагает:\n- Быструю и безопасную передачу сообщений\n- Каналы для публикации контента\n- Группы для общения\n- Боты для автоматизации\n- Голосовые и видеозвонки'
        },
        {
          question: 'Чем Telegram отличается от других мессенджеров?',
          answer: 'Telegram выделяется:\n- Высоким уровнем безопасности и шифрования\n- Отсутствием ограничений на размер файлов\n- Удобными каналами для распространения контента\n- Мощной платформой для ботов\n- Регулярными обновлениями с новыми функциями'
        },
        {
          question: 'Как создать свой канал в Telegram?',
          answer: '1. Откройте Telegram\n2. Нажмите на меню (три полоски)\n3. Выберите "Создать канал"\n4. Заполните название и описание\n5. Настройте приватность\n6. Начните публиковать контент'
        }
      ]
    },
    {
      title: 'Безопасность',
      icon: <SecurityIcon />,
      items: [
        {
          question: 'Как защищены мои данные?',
          answer: 'Мы серьезно относимся к безопасности:\n- Используем шифрование данных\n- Не храним пароли в открытом виде\n- Регулярно обновляем системы безопасности\n- Используем защищенное HTTPS-соединение'
        },
        {
          question: 'Что делать, если я нашел подозрительный канал?',
          answer: 'Если вы обнаружили канал, нарушающий правила:\n1. Нажмите кнопку "Пожаловаться" на странице канала\n2. Опишите причину жалобы\n3. Наши модераторы проверят канал и примут меры'
        }
      ]
    }
  ];

  return (
    <>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={() => getPageSeo('faq')}
        defaultTitle="Часто задаваемые вопросы | TeleHunt.ru"
        defaultDescription="Ответы на часто задаваемые вопросы о каталоге Telegram каналов TeleHunt.ru"
      />
      <Container maxWidth="md" sx={{ py: 8 }}>
        {/* Заголовок */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <HelpOutlineIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
          <Typography variant="h3" component="h1" gutterBottom>
            Часто задаваемые вопросы
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
            Здесь вы найдете ответы на самые популярные вопросы о нашем каталоге и Telegram
          </Typography>
        </Box>

        {/* Секции с вопросами */}
        {faqSections.map((section, index) => (
          <Paper 
            key={section.title}
            elevation={0}
            sx={{ 
              mb: 4,
              overflow: 'hidden',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                bgcolor: 'background.paper',
              }}
            >
              {section.icon}
              <Typography variant="h6" component="h2">
                {section.title}
              </Typography>
            </Box>
            <Divider />
            {section.items.map((item, itemIndex) => (
              <Accordion
                key={itemIndex}
                expanded={expanded === `${index}-${itemIndex}`}
                onChange={handleChange(`${index}-${itemIndex}`)}
                elevation={0}
                disableGutters
                sx={{
                  '&:not(:last-child)': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <Typography 
                    sx={{ 
                      color: 'text.primary',
                      fontSize: '1.1rem',
                      fontWeight: 500
                    }}
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails 
                  sx={{ 
                    bgcolor: 'background.paper',
                    pt: 0,
                    pb: 3,
                    px: 3 
                  }}
                >
                  <Typography
                    color="text.secondary"
                    sx={{
                      whiteSpace: 'pre-line',
                      lineHeight: 1.7,
                      '& strong': { color: 'primary.main' }
                    }}
                  >
                    {item.answer}
                  </Typography>
                  
                  {/* Кнопки оценки полезности */}
                  <Box 
                    sx={{ 
                      mt: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      Был ли ответ полезным?
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleHelpfulRating(index, itemIndex, true)}
                      color={helpfulRatings[`${index}-${itemIndex}`] === true ? 'primary' : 'default'}
                      disabled={helpfulRatings[`${index}-${itemIndex}`] !== undefined}
                    >
                      <ThumbUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleHelpfulRating(index, itemIndex, false)}
                      color={helpfulRatings[`${index}-${itemIndex}`] === false ? 'error' : 'default'}
                      disabled={helpfulRatings[`${index}-${itemIndex}`] !== undefined}
                    >
                      <ThumbDownIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        ))}

        {/* Форма обратной связи */}
        <Box ref={formRef} sx={{ mt: 6, textAlign: 'center' }}>
          <Paper 
            elevation={0}
            sx={{
              p: 4,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              bgcolor: 'background.paper',
            }}
          >
            <Typography variant="body1" color="text.secondary" gutterBottom>
              Не нашли ответ на свой вопрос?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFeedbackOpen(true)}
              sx={{ mt: 1 }}
            >
              Задать вопрос
            </Button>
          </Paper>
        </Box>

        {/* Диалог обратной связи */}
        <Dialog 
          open={feedbackOpen} 
          onClose={() => setFeedbackOpen(false)} 
          maxWidth="sm" 
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
            }
          }}
        >
          <DialogTitle sx={{ 
            pb: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            '& .MuiTypography-root': {
              fontSize: '1.5rem',
              fontWeight: 600,
              color: 'primary.main'
            }
          }}>
            Задать вопрос
          </DialogTitle>
          <DialogContent sx={{ 
            pt: 3,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Box 
              component="form" 
              onSubmit={handleFeedbackSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                width: '100%',
                mt: 2
              }}
            >
              {!user && (
                <Alert 
                  severity="info" 
                  sx={{ 
                    borderRadius: 1,
                    '& .MuiAlert-message': {
                      fontSize: '0.95rem'
                    }
                  }}
                >
                  Для отправки вопроса необходимо войти в систему
                </Alert>
              )}
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label="Ваш вопрос"
                placeholder="Опишите ваш вопрос подробно..."
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
                disabled={!user}
                aria-label="Поле для ввода вопроса"
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1,
                    minHeight: '120px',
                    '& textarea': {
                      minHeight: '40px',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '-8px',
                    '&.MuiInputLabel-shrink': {
                      marginTop: 0
                    }
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}
              />
              <Grid 
                container 
                spacing={2} 
                sx={{
                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    '& .MuiGrid-item': {
                      width: '100%'
                    }
                  }
                }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    type="email"
                    placeholder="example@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!user}
                    aria-label="Поле для ввода email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="primary" sx={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 1,
                        height: '48px'
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '-8px',
                        '&.MuiInputLabel-shrink': {
                          marginTop: 0
                        }
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '0.95rem',
                        '&::placeholder': {
                          fontSize: '0.9rem',
                          opacity: 0.7
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Telegram"
                    placeholder="username"
                    value={telegram}
                    onChange={(e) => setTelegram(e.target.value)}
                    disabled={!user}
                    aria-label="Поле для ввода имени пользователя Telegram"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TelegramIcon color="primary" sx={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 1,
                        height: '48px'
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '-8px',
                        '&.MuiInputLabel-shrink': {
                          marginTop: 0
                        }
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '0.95rem',
                        '&::placeholder': {
                          fontSize: '0.9rem',
                          opacity: 0.7
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            px: 3, 
            py: 2,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}>
            <Button 
              onClick={() => setFeedbackOpen(false)}
              sx={{ 
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                px: 3
              }}
            >
              Отмена
            </Button>
            <Button 
              onClick={handleFeedbackSubmit}
              variant="contained" 
              disabled={!user || !question.trim()}
              sx={{ 
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                px: 3,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
            >
              Отправить
            </Button>
          </DialogActions>
        </Dialog>

        {/* Уведомления */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default FAQ;
