import React from 'react';
import { Box, Typography, Button, Container, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { QuestionAnswer as QuestionAnswerIcon, Home as HomeIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  const navigate = useNavigate();

  const handleAskQuestion = () => {
    navigate('/faq?scroll=form');
  };

  return (
    <>
      <Helmet>
        <title>404 - Страница не найдена | Telegram Catalog</title>
        <meta name="description" content="К сожалению, запрашиваемая страница не найдена. Воспользуйтесь навигацией или поиском, чтобы найти нужную информацию." />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      
      <Container maxWidth="md">
        <Box
          sx={{
            minHeight: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: 8,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              textAlign: 'center',
              borderRadius: 2,
              bgcolor: 'background.paper',
              maxWidth: 600,
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '4rem', sm: '6rem' },
                fontWeight: 700,
                color: 'primary.main',
                mb: 2,
              }}
            >
              404
            </Typography>

            <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
              Страница не найдена
            </Typography>

            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
              К сожалению, запрашиваемая страница не существует или была перемещена.
              Воспользуйтесь навигацией или вернитесь на главную страницу.
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', flexWrap: 'wrap' }}>
              <Button
                variant="contained"
                startIcon={<HomeIcon />}
                onClick={() => navigate('/')}
                sx={{ minWidth: 200 }}
              >
                На главную
              </Button>
              
              <Button
                variant="outlined"
                startIcon={<QuestionAnswerIcon />}
                onClick={handleAskQuestion}
                sx={{ minWidth: 200 }}
              >
                Задать вопрос
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default NotFound;
