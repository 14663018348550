import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Button,
  useTheme,
  alpha,
  useMediaQuery
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Block as BlockIcon,
  ArrowBack as ArrowBackIcon,
  Timer as TimerIcon,
  Add as AddIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const ModerationRules = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Правила модерации | TeleHunt.ru',
    description: 'Правила модерации каналов в каталоге Telegram каналов TeleHunt.ru',
    keywords: 'правила модерации, телеграм каталог, модерация каналов'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('moderationRules');
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);
  
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const allowedContent = [
    {
      title: 'Качественный заголовок',
      description: 'Название канала должно быть информативным, уникальным и отражать его содержание. Это важно для SEO-оптимизации и привлечения целевой аудитории.'
    },
    {
      title: 'Полезное описание',
      description: 'Грамотное и содержательное описание с ключевыми особенностями канала. Хорошее описание помогает как пользователям, так и поисковым системам понять тематику канала.'
    },
    {
      title: 'Релевантные категории',
      description: 'Выбор категорий, точно соответствующих контенту канала. Это улучшает видимость в каталоге и помогает пользователям находить ваш канал.'
    },
    {
      title: 'Активность канала',
      description: 'Канал должен быть активным с регулярными публикациями. Мы не размещаем заброшенные или неактивные каналы.'
    },
    {
      title: 'Приемлемый аватар',
      description: 'Изображение профиля должно соответствовать тематике канала и не содержать запрещенный контент. Для каналов 18+ допускаются эротические изображения без явной обнаженности.'
    }
  ];

  const prohibitedContent = [
    {
      title: 'Спам и мошенничество',
      description: 'Массовые рассылки, навязчивая реклама, финансовые пирамиды и любые формы обмана пользователей.'
    },
    {
      title: 'Нелегальный контент',
      description: 'Материалы, нарушающие законодательство, включая пиратский контент, наркотики, оружие и другие запрещенные товары и услуги.'
    },
    {
      title: 'Неприемлемый контент 18+',
      description: 'Хотя мы разрешаем каналы для взрослых с эротическим контентом, мы не допускаем незаконный контент, насилие и другие формы экстремального контента.'
    },
    {
      title: 'Экстремизм',
      description: 'Пропаганда насилия, дискриминации, расовой или религиозной ненависти, терроризма и других форм экстремизма.'
    },
    {
      title: 'Вредоносное ПО',
      description: 'Распространение вирусов, троянов, вредоносных скриптов и других программ, способных нанести вред пользователям.'
    }
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #1a1a1a, #2d2d2d)',
        position: 'relative',
        pb: { xs: 4, md: 8 },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          background: 'radial-gradient(circle at 50% 0%, rgba(25, 118, 210, 0.15) 0%, transparent 70%)',
          zIndex: 0,
          pointerEvents: 'none'
        }
      }}
    >
      {/* SEO метатеги */}
      <MetaTags 
        title={seoMetadata.title}
        description={seoMetadata.description}
        keywords={seoMetadata.keywords}
        structuredData={seoMetadata.structuredData}
      />
      
      <Container maxWidth="lg" sx={{ pt: { xs: 4, md: 6 }, position: 'relative', zIndex: 1 }}>
        {/* Header */}
        <Box sx={{ 
          mb: { xs: 4, md: 6 }, 
          display: 'flex', 
          alignItems: { xs: 'flex-start', md: 'center' }, 
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 2, md: 3 } 
        }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              backdropFilter: 'blur(10px)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
          >
            <ArrowBackIcon sx={{ color: 'white' }} />
          </IconButton>
          <Box>
            <Typography 
              variant="h3" 
              component="h1" 
              sx={{ 
                color: 'white',
                fontWeight: 700,
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                mb: 1,
                fontSize: { xs: '2rem', md: '3rem' }
              }}
            >
              Правила модерации
            </Typography>
            <Typography 
              variant="h6" 
              sx={{ 
                color: alpha('#fff', 0.7),
                fontWeight: 400,
                fontSize: { xs: '1rem', md: '1.25rem' }
              }}
            >
              Ознакомьтесь с правилами перед добавлением канала
            </Typography>
          </Box>
        </Box>

        {/* Main Content */}
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
          gap: { xs: 3, md: 4 } 
        }}>
          {/* Left Column */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 3, md: 4 } }}>
            {/* Модерация */}
            <Card 
              sx={{ 
                bgcolor: 'rgba(25, 118, 210, 0.1)',
                backdropFilter: { xs: 'none', md: 'blur(20px)' },
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: { xs: 3, md: 4 },
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '100%',
                  background: 'linear-gradient(45deg, rgba(25, 118, 210, 0.05) 0%, rgba(25, 118, 210, 0.2) 100%)',
                  zIndex: 0
                }}
              />
              <CardContent sx={{ position: 'relative', zIndex: 1, p: { xs: 3, md: 4 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <TimerIcon sx={{ color: '#1976d2', fontSize: { xs: 28, md: 32 } }} />
                  <Typography variant="h5" sx={{ color: 'white', fontWeight: 600, fontSize: { xs: '1.25rem', md: '1.5rem' } }}>
                    Время модерации
                  </Typography>
                </Box>
                <Typography sx={{ color: alpha('#fff', 0.7), fontSize: { xs: '0.9rem', md: '1rem' } }}>
                  Модерация обычно занимает от 3 до 24 часов. 
                  Мы рассмотрим ваш канал и примем решение о его публикации.
                  Каналы с контентом 18+ проходят дополнительную проверку.
                </Typography>
              </CardContent>
            </Card>

            {/* Разрешенный контент */}
            <Card 
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.03)',
                backdropFilter: { xs: 'none', md: 'blur(20px)' },
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: { xs: 3, md: 4 }
              }}
            >
              <CardContent sx={{ p: { xs: 3, md: 4 } }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#2ecc71',
                    fontWeight: 600,
                    mb: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  <CheckCircleIcon /> Разрешенный контент
                </Typography>
                <List sx={{ p: 0 }}>
                  {allowedContent.map((item, index) => (
                    <ListItem 
                      key={index} 
                      sx={{ 
                        px: 0,
                        py: { xs: 1.5, md: 2 },
                        borderBottom: index !== allowedContent.length - 1 ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              color: 'white',
                              fontWeight: 500,
                              mb: 0.5,
                              fontSize: { xs: '1rem', md: '1.25rem' }
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ color: alpha('#fff', 0.7), fontSize: { xs: '0.875rem', md: '1rem' } }}>
                            {item.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Call to Action - только для ПК */}
            <Card 
              sx={{ 
                bgcolor: '#1976d2',
                borderRadius: { xs: 3, md: 4 },
                position: 'relative',
                overflow: 'hidden',
                display: { xs: 'none', md: 'block' } // Показываем только на ПК
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '100%',
                  background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%)',
                  zIndex: 0
                }}
              />
              <CardContent sx={{ position: 'relative', zIndex: 1, p: { xs: 3, md: 4 } }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: 'white',
                    fontWeight: 600,
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Готовы добавить канал?
                </Typography>
                <Typography 
                  sx={{ 
                    color: alpha('#fff', 0.9),
                    mb: 3,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}
                >
                  Убедитесь, что ваш канал соответствует всем правилам, и приступайте к добавлению.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/add-channel')}
                  sx={{
                    bgcolor: 'white',
                    color: '#1976d2',
                    px: 4,
                    py: 1.5,
                    '&:hover': {
                      bgcolor: alpha('#fff', 0.9)
                    }
                  }}
                >
                  Добавить канал
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Right Column */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 3, md: 4 } }}>
            {/* Информация о каналах 18+ */}
            <Card 
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.03)',
                backdropFilter: { xs: 'none', md: 'blur(20px)' },
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: { xs: 3, md: 4 }
              }}
            >
              <CardContent sx={{ p: { xs: 3, md: 4 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <InfoIcon sx={{ color: '#f39c12', fontSize: { xs: 28, md: 32 } }} />
                  <Typography variant="h5" sx={{ color: 'white', fontWeight: 600, fontSize: { xs: '1.25rem', md: '1.5rem' } }}>
                    О каналах 18+
                  </Typography>
                </Box>
                <Typography sx={{ color: alpha('#fff', 0.7), mb: 2, fontSize: { xs: '0.9rem', md: '1rem' } }}>
                  Мы разрешаем размещение каналов с контентом для взрослых (18+) при соблюдении следующих условий:
                </Typography>
                <Box component="ul" sx={{ color: alpha('#fff', 0.7), pl: 3, m: 0 }}>
                  <Box component="li" sx={{ mb: 1, fontSize: { xs: '0.9rem', md: '1rem' } }}>
                    Канал должен иметь соответствующую маркировку 18+
                  </Box>
                  <Box component="li" sx={{ mb: 1, fontSize: { xs: '0.9rem', md: '1rem' } }}>
                    Контент должен быть легальным и не нарушать законодательство
                  </Box>
                  <Box component="li" sx={{ mb: 1, fontSize: { xs: '0.9rem', md: '1rem' } }}>
                    Аватар канала не должен содержать явную обнаженность
                  </Box>
                  <Box component="li" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                    Описание должно быть информативным, без излишне откровенных деталей
                  </Box>
                </Box>
              </CardContent>
            </Card>

            {/* Запрещенный контент */}
            <Card 
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.03)',
                backdropFilter: { xs: 'none', md: 'blur(20px)' },
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: { xs: 3, md: 4 }
              }}
            >
              <CardContent sx={{ p: { xs: 3, md: 4 } }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#e74c3c',
                    fontWeight: 600,
                    mb: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  <BlockIcon /> Запрещенный контент
                </Typography>
                <List sx={{ p: 0 }}>
                  {prohibitedContent.map((item, index) => (
                    <ListItem 
                      key={index} 
                      sx={{ 
                        px: 0,
                        py: { xs: 1.5, md: 2 },
                        borderBottom: index !== prohibitedContent.length - 1 ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              color: 'white',
                              fontWeight: 500,
                              mb: 0.5,
                              fontSize: { xs: '1rem', md: '1.25rem' }
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ color: alpha('#fff', 0.7), fontSize: { xs: '0.875rem', md: '1rem' } }}>
                            {item.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Call to Action - только для мобильных */}
            <Card 
              sx={{ 
                bgcolor: '#1976d2',
                borderRadius: { xs: 3, md: 4 },
                position: 'relative',
                overflow: 'hidden',
                display: { xs: 'block', md: 'none' } // Показываем только на мобильных
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '100%',
                  background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%)',
                  zIndex: 0
                }}
              />
              <CardContent sx={{ position: 'relative', zIndex: 1, p: { xs: 3, md: 4 } }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: 'white',
                    fontWeight: 600,
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Готовы добавить канал?
                </Typography>
                <Typography 
                  sx={{ 
                    color: alpha('#fff', 0.9),
                    mb: 3,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}
                >
                  Убедитесь, что ваш канал соответствует всем правилам, и приступайте к добавлению.
                </Typography>
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/add-channel')}
                  sx={{
                    bgcolor: 'white',
                    color: '#1976d2',
                    px: 3,
                    py: 1,
                    '&:hover': {
                      bgcolor: alpha('#fff', 0.9)
                    }
                  }}
                >
                  Добавить канал
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ModerationRules;
