import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Paper,
  Button,
  TextField,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Avatar
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Предотвращаем стандартную отправку формы
    
    setError('');
    setSuccess(false);

    // Валидация username
    if (!username) {
      setError('Необходимо указать имя пользователя');
      return;
    }
    if (username.length < 3) {
      setError('Имя пользователя должно содержать не менее 3 символов');
      return;
    }

    // Валидация пароля
    if (!password) {
      setError('Необходимо указать пароль');
      return;
    }
    if (password.length < 6) {
      setError('Пароль должен содержать не менее 6 символов');
      return;
    }

    try {
      setLoading(true);
      const result = await login(username, password);
      console.log('Login successful:', result);
      
      if (result && result.success) {
        setSuccess(true);
        
        // Проверяем, является ли пользователь администратором
        try {
          const response = await fetch('/api/stats/admin', {
            headers: {
              'Authorization': `Bearer ${result.token}`
            }
          });
          
          // Если пользователь администратор, перенаправляем в админ-панель
          if (response.status === 200) {
            navigate('/admin/dashboard');
          } else {
            navigate('/');
          }
        } catch (error) {
          console.error('Ошибка при проверке прав администратора:', error);
          navigate('/');
        }
      } else {
        setError(result?.error || 'Ошибка входа: Токен не получен');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (!navigator.onLine) {
        setError('Нет подключения к интернету. Пожалуйста, проверьте ваше соединение.');
      } else if (err.response?.status === 401) {
        setError('Неверное имя пользователя или пароль');
      } else if (err.response?.data?.msg) {
        setError(err.response.data.msg);
      } else if (!err.response) {
        setError('Не удалось подключиться к серверу. Пожалуйста, попробуйте позже.');
      } else {
        setError(err.message || 'Произошла ошибка. Пожалуйста, попробуйте снова.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #0088cc 0%, #005580 100%)',
        py: 8
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#0088cc', width: 56, height: 56 }}>
            <LockOutlinedIcon sx={{ fontSize: 32 }} />
          </Avatar>
          <Typography 
            component="h1" 
            variant="h5" 
            sx={{ 
              mb: 3,
              color: '#000000',
              fontWeight: 600,
              fontSize: '1.75rem'
            }}
          >
            Вход в аккаунт
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: '100%' }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Вход выполнен успешно! Перенаправление...
              </Alert>
            )}
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Имя пользователя"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
              sx={{
                '& label': { color: '#666666' },
                '& label.Mui-focused': { color: '#0088cc' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#cccccc' },
                  '&:hover fieldset': { borderColor: '#0088cc' },
                  '&.Mui-focused fieldset': { borderColor: '#0088cc' },
                },
                '& input': { 
                  color: '#000000',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000000'
                  }
                }
              }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              sx={{
                '& label': { color: '#666666' },
                '& label.Mui-focused': { color: '#0088cc' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#cccccc' },
                  '&:hover fieldset': { borderColor: '#0088cc' },
                  '&.Mui-focused fieldset': { borderColor: '#0088cc' },
                },
                '& input': { 
                  color: '#000000',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000000'
                  }
                }
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2,
                bgcolor: '#0088cc',
                '&:hover': {
                  bgcolor: '#006699'
                }
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Войти'}
            </Button>
            
            <Button
              component={Link}
              to="/register"
              fullWidth
              variant="outlined"
              sx={{
                color: '#0088cc',
                borderColor: '#0088cc',
                textDecoration: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(0, 136, 204, 0.08)',
                  borderColor: '#006699',
                  color: '#006699'
                }
              }}
            >
              Нет аккаунта? Зарегистрироваться
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
