import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const Terms = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: '',
    description: '',
    keywords: ''
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('terms');
        if (metadata) {
          console.log('[INFO] Terms - Получены SEO-метаданные:', {
            title: metadata.title,
            description: metadata.description ? metadata.description.substring(0, 30) + '...' : 'отсутствует',
            hasOgData: !!(metadata.ogTitle || metadata.ogDescription || metadata.ogImage)
          });
          setSeoMetadata(metadata);
        } else {
          console.error('[ERROR] Terms - Не получены SEO-метаданные от API');
        }
      } catch (error) {
        console.error('[ERROR] Terms - Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);

  return (
    <>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={() => getPageSeo('terms')}
        defaultTitle="Правила использования | TeleHunt.ru"
        defaultDescription="Правила использования каталога Telegram каналов TeleHunt.ru"
      />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Правила использования и отказ от ответственности
        </Typography>
        <Paper sx={{ p: 4, mt: 3 }}>
          <Box sx={{ '& > *': { mb: 3 } }}>
            <Typography variant="h6" gutterBottom>
              1. Общие положения
            </Typography>
            <Typography variant="body1" paragraph>
              Используя наш каталог Telegram каналов, вы соглашаетесь с настоящими правилами 
              использования и отказом от ответственности.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Отказ от ответственности
            </Typography>
            <Typography variant="body1" paragraph>
              Мы не несем ответственности за:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">Содержание Telegram каналов</Typography>
              <Typography component="li" variant="body1">Действия владельцев каналов</Typography>
              <Typography component="li" variant="body1">Любой ущерб, связанный с использованием каналов</Typography>
            </ul>

            <Typography variant="h6" gutterBottom>
              3. Правила использования
            </Typography>
            <Typography variant="body1" paragraph>
              При использовании каталога вы обязуетесь:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">Не нарушать законодательство РФ и других стран</Typography>
              <Typography component="li" variant="body1">Не добавлять каналы с запрещенным контентом</Typography>
              <Typography component="li" variant="body1">Не использовать автоматизированные средства для сбора данных</Typography>
            </ul>

            <Typography variant="h6" gutterBottom>
              4. Модерация каналов
            </Typography>
            <Typography variant="body1" paragraph>
              Мы оставляем за собой право:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">Отклонять каналы, не соответствующие правилам</Typography>
              <Typography component="li" variant="body1">Удалять каналы без предупреждения</Typography>
              <Typography component="li" variant="body1">Изменять категории и описания каналов</Typography>
            </ul>

            <Typography variant="h6" gutterBottom>
              5. Изменение правил
            </Typography>
            <Typography variant="body1">
              Мы оставляем за собой право изменять настоящие правила в любое время без предварительного уведомления.
              Продолжая использовать каталог после внесения изменений, вы соглашаетесь с обновленными правилами.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Terms;
